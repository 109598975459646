
<template>
  <div class="Approve_I">
    <div class="headNavs">
      <div style="display:flex">
        <el-menu
          :default-active="activeIndex"
          class="el-menu-demo"
          mode="horizontal"
          @select="handleSelect">
          <el-menu-item index="1">审批数据</el-menu-item>
          <el-menu-item index="2">表单管理</el-menu-item>
        </el-menu>
      </div>
      <div class="headDetails">说明：设置考勤部门与人员、考勤班次、考勤方式（Wi-Fi）等相关规则，点击下方添加考勤进入按步骤设置即可。</div>
    </div>

    <div v-if="activeIndex == 1">
      <div class="capitalNav">
        <div style="display:flex;justify-content: space-between;">
          <div class="NavLi">
            <div class="NavLiName">审批类型：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.form_id" placeholder="请选择" @change="chanange_Shenpi" style="width:13.75rem">
                <el-option v-for="(item,i) in formList" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">审批状态：</div>
            <div class="NavLiIpt">
              <el-select v-model="search.status" placeholder="请选择审批状态">
                <el-option v-for="(item,i) in statusList" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">审批编号：</div>
            <div class="NavLiIpt">
              <el-input v-model="search.approval_no" placeholder="请填写编号" ></el-input>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">发起人：</div>
            <div class="NavLiIpt">
              <el-input v-model="value3" placeholder="请选择发起人" readonly @focus="Bmen1(3)"></el-input>
            </div>
          </div>
          <div class="NavLi"></div>
          <div class="NavLi"></div>
        </div>
        <div style="display:flex;justify-content: space-between;margin-top:1.25rem">
          <div class="NavLi">
            <div class="NavLiName">提交时间：</div>
            <div class="NavLiIpt">
              <el-date-picker v-model="search.starttime.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
              <span class="timeSpan">至</span>
              <el-date-picker v-model="search.starttime.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            </div>
          </div>
          <div class="NavLi">
            <div class="NavLiName">完成时间：</div>
            <div class="NavLiIpt">
              <el-date-picker v-model="search.endtime.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
              <span class="timeSpan">至</span>
              <el-date-picker v-model="search.endtime.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            </div>
            <div class="NavLiQuery" style="margin-left: 1.5rem;" @click="BtnSearch()">查询</div>
            <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
          </div>
          <div class="NavLi"></div>
          <div class="NavLi"></div>
        </div>
      </div>
      <div class="TablesList">
        <div class="TablesADD">
          <div class="NavLiReset" @click="export2Excel()"><i class="el-icon-download"></i>导出</div>
          <div class="NavLiQuery" @click="showAdd(1)"><i class="el-icon-plus"></i>创建审批</div>
          <div class="TableIpt"> 
            <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入说明">
            <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
          </div>
        </div>
        <el-table
          :data="tableData"
          class="table"
          ref="multipleTable"
          header-cell-class-name="table-header"
          @selection-change="Selection">
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <el-table-column prop="id" label="ID" width="55" align="center" ></el-table-column>
          <el-table-column align="center" label="图片" width="100">
            <template #default="scope">
                <div class="el_butto">
                  <img class="Toper" :src="scope.row.staff.full_avatar" alt="">
                </div>
            </template>          
          </el-table-column>
          <el-table-column align="center" prop="approval_no" label="审批编号" width="140"></el-table-column>
          <el-table-column align="center" prop="staff.realname" label="发起人" width="100"></el-table-column>
          <el-table-column align="center" prop="form.name" label="审批类型"></el-table-column>
          <el-table-column align="center" prop="status_text" label="审批状态"></el-table-column>
          <el-table-column align="center" prop="content" label="说明" width="400"></el-table-column>
          <el-table-column align="center" prop="updatetime" label="提交时间" ></el-table-column>
          <el-table-column align="center" label="操作" width="140">
            <template #default="scope" >
                <div class="el_butto">
                  <el-button
                  type="text"
                  @click="HandleSee(scope.row)"
                  >查看</el-button>
                </div>
              <div class="el_butto rere" >
                  <el-button
                  type="text"
                  class="red"
                  @click="HandleDel(3,scope.row)"
                  >删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="block">
          <el-pagination
              background
              @current-change="handleCurrentChange"
              :current-page="page.currentPage"
              :page-size="page.pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
    

    <div v-else>
      <div class="TablesList">
        <div class="TablesADD">
          <div class="NavLiReset" @click="showAdd(2)"><i class="el-icon-wallet"></i>新建分组</div>
          <div class="NavLiReset" @click="showAdd()"><i class="el-icon-user"></i>分组排序</div>
        </div>
        <div class="Approval" v-for="item in tableData2" :key="item.id">
          <div class="ApprovalTop">
            <div>{{item.name}}</div>
            <div class="topOne">
              <div @click="HandlEdit(1, item)">修改</div>
              <div @click="HandleDel(2, item)">删除</div>
            </div>
          </div>
          <div class="ApprovalLi" v-for="obj in item.form" :key="obj.id">
            <div class="ApprovalLione">
              <div>{{obj.name}}</div>
              <div>全员可见</div>
            </div>
            <div class="ApprovalLitwo">
              <div @click="HandlEdit(2, obj)">查看</div>
              <div @click="HandleDel(1, obj)">删除</div>
            </div>
          </div>
        </div>
      </div>
    </div>





    <el-dialog :title="titleMath" v-model="editVisible" width="38%">
      <div class="EditNewForm" style="padding:0">
        <div class="invoice" v-if="form_id == 1">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>请假类型</div><div>{{FormIdName}}</div></div>
          <div class="invoiceLi"><div>开始时间</div><div>{{formatDate(FormInfo.starttime)}}</div></div>
          <div class="invoiceLi"><div>结束时间</div><div>{{formatDate(FormInfo.endtime)}}</div></div>
          <div class="invoiceLi"><div>请假天数</div><div>{{FormInfo.days}}</div></div>
          <div class="invoiceLi"><div>请假理由</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 2">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>出差地点</div><div>{{FormInfo.address}}</div></div>
          <div class="invoiceLi"><div>开始时间</div><div>{{formatDate(FormInfo.starttime)}}</div></div>
          <div class="invoiceLi"><div>结束时间</div><div>{{formatDate(FormInfo.endtime)}}</div></div>
          <div class="invoiceLi rr"><div>出差理由</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 3">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>报销金额</div><div>{{FormInfo.price}}</div></div>
          <div class="invoiceLi"><div>报销类别</div><div>{{FormInfo.type}}</div></div>
          <div class="invoiceLi"><div>报销时间</div><div>{{formatDate(FormInfo.time)}}</div></div>
          <div class="invoiceLi rr"><div >报销明细</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 4">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>物品名称</div><div>{{FormInfo.goods}}</div></div>
          <div class="invoiceLi"><div>采购用途</div><div>{{FormInfo.purpose}}</div></div>
          <div class="invoiceLi"><div>物品单价</div><div>{{FormInfo.price}}</div></div>
          <div class="invoiceLi"><div>采购数量</div><div>{{FormInfo.number}}</div></div>
          <div class="invoiceLi"><div>申请理由</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 5">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>付款金额</div><div>{{FormInfo.price}}</div></div>
          <div class="invoiceLi"><div>付款时间</div><div>{{formatDate(FormInfo.time)}}</div></div>
          <div class="invoiceLi"><div>支付项目</div><div>{{FormInfo.goods}}</div></div>
          <div class="invoiceLi"><div>支付对象</div><div>{{FormInfo.object}}</div></div>
          <div class="invoiceLi"><div>对方账户</div><div>{{FormInfo.account}}</div></div>
          <div class="invoiceLi"><div>开户行</div><div>{{FormInfo.bank}}</div></div>
          <div class="invoiceLi"><div>资金使用说明</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 6">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>开始时间</div><div>{{formatDate(FormInfo.starttime)}}</div></div>
          <div class="invoiceLi"><div>结束时间</div><div>{{formatDate(FormInfo.endtime)}}</div></div>
          <div class="invoiceLi"><div>加班时长</div><div>{{FormInfo.hours}}</div></div>
          <div class="invoiceLi rr"><div>加班原因</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 7">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>发票类型</div><div>{{FormInfo.type}}</div></div>
          <div class="invoiceLi"><div>客户名称</div><div>{{FormInfo.clients}}</div></div>
          <div class="invoiceLi"><div>开票内容</div><div>{{FormInfo.info}}</div></div>
          <div class="invoiceLi"><div>开票金额</div><div>{{FormInfo.price}}</div></div>
          <div class="invoiceLi"><div>发票抬头</div><div>{{FormInfo.invoice_title}}</div></div>
          <div class="invoiceLi"><div>税号</div><div>{{FormInfo.tax_num}}</div></div>
          <div class="invoiceLi"><div>开票时间</div><div>{{formatDate(FormInfo.invoice_time)}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 8">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>物品名称</div><div>{{FormInfo.goods}}</div></div>
          <div class="invoiceLi"><div>物品用途</div><div>{{FormInfo.purpose}}</div></div>
          <div class="invoiceLi"><div>领用数量</div><div>{{FormInfo.number}}</div></div>
          <div class="invoiceLi rr"><div>具体详情</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 9">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>入职日期</div><div>{{formatDate(FormInfo.entry_time)}}</div></div>
          <div class="invoiceLi"><div>离职日期</div><div>{{formatDate(FormInfo.quit_time)}}</div></div>
          <div class="invoiceLi"><div>所在岗位</div><div>{{FormInfo.job}}</div></div>
          <div class="invoiceLi"><div>交接人</div><div>{{FormInfo.person}}</div></div>
          <div class="invoiceLi"><div>离职原因</div><div>{{FormInfo.reason}}</div></div>
          <div class="invoiceLi rr"><div>交接清单</div><div>{{FormInfo.check_list}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 10">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>申请金额</div><div>{{FormInfo.price}}</div></div>
          <div class="invoiceLi"><div>资金用途</div><div>{{FormInfo.purpose}}</div></div>
          <div class="invoiceLi"><div>支付方式</div><div>{{FormInfo.type}}</div></div>
          <div class="invoiceLi rr"><div>资金使用说明</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 11">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>客户名称</div><div>{{FormInfo.clients}}</div></div>
          <div class="invoiceLi"><div>退费项目</div><div>{{FormInfo.goods}}</div></div>
          <div class="invoiceLi"><div>退费金额</div><div>{{FormInfo.price}}</div></div>
          <div class="invoiceLi"><div>退费时间</div><div>{{formatDate(FormInfo.refund_time)}}</div></div>
          <div class="invoiceLi"><div>退费对象</div><div>{{FormInfo.object}}</div></div>
          <div class="invoiceLi"><div>对方账户</div><div>{{FormInfo.account}}</div></div>
          <div class="invoiceLi"><div>开户行</div><div>{{FormInfo.bank}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 12">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>外出时间</div><div>{{formatDate(FormInfo.starttime)}}</div></div>
          <div class="invoiceLi"><div>回来时间</div><div>{{formatDate(FormInfo.endtime)}}</div></div>
          <div class="invoiceLi"><div>外出时长</div><div>{{FormInfo.gotime}}</div></div>
          <div class="invoiceLi rr"><div>外出事由</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 13">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>印章类型</div><div>{{FormInfo.stamp_type}}</div></div>
          <div class="invoiceLi"><div>用章时间</div><div>{{formatDate(FormInfo.time)}}</div></div>
          <div class="invoiceLi"><div>文件类型</div><div>{{FormInfo.file_type}}</div></div>
          <div class="invoiceLi rr"><div>文件份数</div><div>{{FormInfo.number}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 14">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>招聘职位</div><div>{{FormInfo.job}}</div></div>
          <div class="invoiceLi"><div>需求人数</div><div>{{FormInfo.need_num}}</div></div>
          <div class="invoiceLi"><div>现有人数</div><div>{{FormInfo.now_num}}</div></div>
          <div class="invoiceLi rr"><div>岗位职责要求</div><div>{{RecordInfo.content}}</div></div>
        </div>
        <div class="invoice" v-if="form_id == 15">
          <div class="invoiceLi"><div>审批人姓名</div><div>{{oneList ? oneList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>抄送人姓名</div><div>{{twoList ? twoList.realname : ''}}</div></div>
          <div class="invoiceLi"><div>审批状态</div><div>{{RecordInfo.status_text}}</div></div>
          <div class="invoiceLi"><div>入职日期</div><div>{{formatDate(FormInfo.entry_time)}}</div></div>
          <div class="invoiceLi"><div>试用日期</div><div>{{formatDate(FormInfo.probation)}}</div></div>
          <div class="invoiceLi"><div>试用岗位</div><div>{{FormInfo.job}}</div></div>
          <div class="invoiceLi"><div>岗位理解</div><div>{{FormInfo.understand}}</div></div>
          <div class="invoiceLi"><div>试用期总结</div><div>{{FormInfo.summary}}</div></div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName CC">审批内容：</div>
          <div class="FormIpt">{{RecordInfo.content}}</div>
        </div>
        <div class="EditNewFormBtns">
          <div class="EditNewFormItemName CC">附件：</div>
          <el-image
            style="width: 5rem; height: 5rem"
            :src="RecordInfo.img_url"
            :fit="fit"></el-image>
        </div>
      </div>
    </el-dialog>

    <!-- 创建自定义审批 -->
    <el-dialog :title="titleMath" v-model="editVisible1" width="30%">
      <div class="EditNewForm ss" v-if="isEdit == 1">
        <div class="EditNewFormBtns">
          <div class="ItemName" @click="showAdd(3)">创建自定义审批</div>
        </div>
        <div class="EditNewFormBtns">
          <div class="ItemNames">使用审批模板</div>
        </div>
        <div class="EditNewFormItemTube lll" v-for="obj in FormList1" :key="obj.id">
          <div class="EditNewFormItemIpt" @click="AppAdd(obj)">
            <div>{{obj.name}}</div>
            <div>{{obj.intro}}</div> 
          </div>
        </div>
      </div>
      <el-form label-width="115px" v-if="formId == 1"> 
        <el-form-item label="请假类型：">
          <el-input v-model="Form2.type" placeholder="请输入分组名称"></el-input>
        </el-form-item>
        <el-form-item label="开始时间：">
          <el-date-picker v-model="Form2.starttime" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="结束时间：">
          <el-date-picker v-model="Form2.endtime" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="请假天数：">
          <el-input v-model="Form2.days" placeholder="请输入请假天数"></el-input>
        </el-form-item> 
        <el-form-item label="请假理由：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入请假理由"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 2"> 
        <el-form-item label="出差地点：">
          <el-input v-model="Form2.address" placeholder="请输入分组名称"></el-input>
        </el-form-item>
        <el-form-item label="开始时间：">
          <el-date-picker v-model="Form2.starttime" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="结束时间：">
          <el-date-picker v-model="Form2.endtime" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="请假天数：">
          <el-input v-model="Form2.days" placeholder="请输入请假天数"></el-input>
        </el-form-item> 
        <el-form-item label="出差理由：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入出差理由"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 3"> 
        <el-form-item label="报销金额：">
          <el-input v-model="Form2.price" placeholder="请输入分组名称"></el-input>
        </el-form-item>
        <el-form-item label="报销类别：">
          <el-input v-model="Form2.type" placeholder="请输入报销类别"></el-input>
        </el-form-item> 
        <el-form-item label="报销时间：">
          <el-date-picker v-model="Form2.time" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="报销明细：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入报销理由"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 4"> 
        <el-form-item label="物品名称：">
          <el-input v-model="Form2.goods" placeholder="请输入分组名称"></el-input>
        </el-form-item>
        <el-form-item label="采购用途：">
          <el-input v-model="Form2.purpose" placeholder="请输入报销类别"></el-input>
        </el-form-item> 
        <el-form-item label="物品单价：">
          <el-input v-model="Form2.price" placeholder="请输入报销类别"></el-input>
        </el-form-item> 
        <el-form-item label="物品名称：">
          <el-input v-model="Form2.goods" placeholder="请输入报销类别"></el-input>
        </el-form-item> 
        <el-form-item label="采购数量：">
          <el-input v-model="Form2.number" placeholder="请输入报销类别"></el-input>
        </el-form-item> 
        <el-form-item label="申请理由：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入申请理由"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 5"> 
        <el-form-item label="付款金额：">
          <el-input v-model="Form2.price" placeholder="请输入付款金额"></el-input>
        </el-form-item>
        <el-form-item label="付款时间：">
          <el-date-picker v-model="Form2.time" type="date" placeholder="选择付款时间" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="支付项目：">
          <el-input v-model="Form2.goods" placeholder="请输入支付项目"></el-input>
        </el-form-item> 
        <el-form-item label="支付对象：">
          <el-input v-model="Form2.object" placeholder="请输入支付对象"></el-input>
        </el-form-item> 
        <el-form-item label="对方账户：">
          <el-input v-model="Form2.account" placeholder="请输入对方账户"></el-input>
        </el-form-item> 
        <el-form-item label="开户行：">
          <el-input v-model="Form2.bank" placeholder="请输入开户行"></el-input>
        </el-form-item> 
        <el-form-item label="资金使用说明：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入资金使用说明"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 6"> 
        <el-form-item label="开始时间：">
          <el-date-picker v-model="Form2.starttime" type="date" placeholder="选择日期" :clearable="false"></el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间：">
          <el-date-picker v-model="Form2.endtime" type="date" placeholder="选择付款时间" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="加班时长：">
          <el-input v-model="Form2.hours" placeholder="请输入加班时长"></el-input>
        </el-form-item> 
        <el-form-item label="加班原因：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入加班原因"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 7"> 
        <el-form-item label="发票类型：">
          <el-input v-model="Form2.type" placeholder="请输入发票类型"></el-input>
        </el-form-item>
        <el-form-item label="客户名称：">
          <el-input v-model="Form2.clients" placeholder="请输入客户名称"></el-input>
        </el-form-item> 
        <el-form-item label="开票内容：">
          <el-input v-model="Form2.info" placeholder="请输入开票内容"></el-input>
        </el-form-item> 
        <el-form-item label="开票金额：">
          <el-input v-model="Form2.price" placeholder="请输入开票金额"></el-input>
        </el-form-item> 
        <el-form-item label="发票抬头：">
          <el-input v-model="Form2.invoice_title" placeholder="请输入发票抬头"></el-input>
        </el-form-item> 
        <el-form-item label="税号：">
          <el-input v-model="Form2.tax_num" placeholder="请输入税号"></el-input>
        </el-form-item> 
        <el-form-item label="开票时间：">
          <el-date-picker v-model="Form2.invoice_time" type="date" placeholder="选择开票时间" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入备注"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 8"> 
        <el-form-item label="物品名称：">
          <el-input v-model="Form2.goods" placeholder="请输入物品名称"></el-input>
        </el-form-item>
        <el-form-item label="物品用途：">
          <el-input v-model="Form2.purpose" placeholder="请输入物品用途"></el-input>
        </el-form-item> 
        <el-form-item label="领用数量：">
          <el-input v-model="Form2.number" placeholder="请输入领用数量"></el-input>
        </el-form-item> 
        <el-form-item label="具体详情：">
          <el-input v-model="info.content" placeholder="请输入具体详情"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 9">
        <el-form-item label="入职日期：">
          <el-date-picker v-model="Form2.entry_time" type="date" placeholder="选择入职日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="离职日期：">
          <el-date-picker v-model="Form2.quit_time" type="date" placeholder="选择入职日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="所在岗位：">
          <el-input v-model="Form2.job" placeholder="请输入所在岗位"></el-input>
        </el-form-item> 
        <el-form-item label="交接人：">
          <el-input v-model="Form2.person" placeholder="请输入交接人"></el-input>
        </el-form-item> 
        <el-form-item label="离职原因：">
          <el-input v-model="Form2.reason" placeholder="请输入离职原因"></el-input>
        </el-form-item> 
        <el-form-item label="交接清单：">
          <el-input v-model="Form2.check_list" placeholder="请输入交接清单"></el-input>
        </el-form-item> 
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入备注"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 10"> 
        <el-form-item label="申请金额：">
          <el-input v-model="Form2.price" placeholder="请输入申请金额"></el-input>
        </el-form-item>
        <el-form-item label="资金用途：">
          <el-input v-model="Form2.purpose" placeholder="请输入资金用途"></el-input>
        </el-form-item> 
        <el-form-item label="支付方式：">
          <el-input v-model="Form2.type" placeholder="请输入支付方式"></el-input>
        </el-form-item> 
        <el-form-item label="资金使用说明：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入资金使用说明"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 11"> 
        <el-form-item label="客户名称：">
          <el-input v-model="Form2.clients" placeholder="请输入客户名称"></el-input>
        </el-form-item>
        <el-form-item label="退费项目：">
          <el-input v-model="Form2.goods" placeholder="请输入退费项目"></el-input>
        </el-form-item> 
        <el-form-item label="退费金额：">
          <el-input v-model="Form2.price" placeholder="请输入退费金额"></el-input>
        </el-form-item> 
        <el-form-item label="退费时间：">
          <el-date-picker v-model="Form2.refund_time" type="date" placeholder="选择退费时间" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="退费对象：">
          <el-input v-model="Form2.object" placeholder="请输入退费对象"></el-input>
        </el-form-item> 
        <el-form-item label="对方账户：">
          <el-input v-model="Form2.account" placeholder="请输入对方账户"></el-input>
        </el-form-item> 
        <el-form-item label="开户行：">
          <el-input v-model="Form2.bank" placeholder="请输入开户行"></el-input>
        </el-form-item> 
        <el-form-item label="出差理由：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入出差理由"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 12"> 
        <el-form-item label="外出时间：">
          <el-date-picker v-model="Form2.starttime" type="date" placeholder="选择外出时间" :clearable="false"></el-date-picker>
        </el-form-item>
        <el-form-item label="回来时间：">
          <el-date-picker v-model="Form2.endtime" type="date" placeholder="选择回来时间" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="外出时长：">
          <el-input v-model="Form2.gotime" placeholder="请输入外出时长"></el-input>
        </el-form-item> 
        <el-form-item label="外出理由：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入外出理由"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 13"> 
        <el-form-item label="印章类型：">
          <el-input v-model="Form2.stamp_type" placeholder="请输入印章类型"></el-input>
        </el-form-item>
        <el-form-item label="用章时间：">
          <el-date-picker v-model="Form2.time" type="date" placeholder="选择回来时间" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="文件类型：">
          <el-input v-model="Form2.file_type" placeholder="请输入文件类型"></el-input>
        </el-form-item> 
        <el-form-item label="文件份数：">
          <el-input type="number" v-model="Form2.number" placeholder="请输入文件份数"></el-input>
        </el-form-item>
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入备注"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="formId == 14"> 
        <el-form-item label="招聘职位：">
          <el-input v-model="Form2.job" placeholder="请输入招聘职位"></el-input>
        </el-form-item>
        <el-form-item label="需求人数：">
          <el-input v-model="Form2.need_num" placeholder="请输入需求人数"></el-input>
        </el-form-item> 
        <el-form-item label="现有人数：">
          <el-input v-model="Form2.now_num" placeholder="请输入现有人数"></el-input>
        </el-form-item> 
        <el-form-item label="岗位职责要求：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入岗位职责要求"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="124px" v-if="formId == 15"> 
        <el-form-item label="入职日期：">
          <el-date-picker v-model="Form2.entry_time" type="date" placeholder="选择入职日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="试用日期：">
          <el-date-picker v-model="Form2.probation" type="date" placeholder="选择试用日期" :clearable="false"></el-date-picker>
        </el-form-item> 
        <el-form-item label="试用岗位：">
          <el-input v-model="Form2.job" placeholder="请输入试用岗位"></el-input>
        </el-form-item>
        <el-form-item label="对本岗位的理解：">
          <el-input v-model="Form2.understand" placeholder="请输入对本岗位的理解"></el-input>
        </el-form-item> 
        <el-form-item label="试用期内总结：">
          <el-input v-model="Form2.summary" placeholder="请输入试用期内总结"></el-input>
        </el-form-item>  
        <el-form-item label="备注：">
          <el-input type="textarea" v-model="info.content" placeholder="请输入备注"></el-input>
        </el-form-item> 
      </el-form>
      <el-form label-width="115px" v-if="isEdit == 2"> 
        <el-form-item label="图片：">
          <el-upload
            :action="upload"
            :headers="heads"
            list-type="picture-card"
            :limit="1"
            :on-success="UP_Success"
            :on-preview="handlePictureCardPreview">
            <i class="el-icon-plus"></i>
            <div class="el_asff">上传图片</div>
          </el-upload>
        </el-form-item>
        <el-form-item label="接收人：">
          <el-input v-model="value1" placeholder="请选择接收人" readonly @focus="Bmen1(1)"></el-input>
        </el-form-item> 
        <el-form-item label="抄送人：">
          <el-input v-model="value2" placeholder="请选择抄送人" readonly @focus="Bmen1(2)"></el-input>
        </el-form-item> 
        <el-form-item>
          <el-button type="primary" style="float: right;" @click="saveEdit()">确 认</el-button>
        </el-form-item> 
      </el-form>
    </el-dialog>

    <!-- 创建分组 -->
    <el-dialog :title="titleMath" v-model="editVisible2" width="30%">
     <el-form style="padding-right: 1.25rem;" label-width="115px" v-if="isGroup == 1"> 
        <el-form-item label="分组名称：">
          <el-input v-model="FormName" placeholder="请输入分组名称"></el-input>
        </el-form-item>
     </el-form>
     <el-form style="padding-right: 1.25rem;" label-width="115px" v-if="isGroup == 2"> 
        <el-form-item label="表单名称：">
          <el-input v-model="From.name" placeholder="请输入表单名称"></el-input>
        </el-form-item>
        <el-form-item label="表单介绍：">
          <el-input v-model="From.intro" placeholder="请输入表单介绍"></el-input>
        </el-form-item>
     </el-form>
     <el-form style="padding-right: 1.25rem;" label-width="115px" v-if="isGroup == 3"> 
        <el-form-item label="审批分组：">
          <el-select v-model="From.group_id" placeholder="请选择审批分组">
            <el-option v-for="item in tableData2" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审批名称：">
          <el-input v-model="From.name" placeholder="请输入审批名称"></el-input>
        </el-form-item>
        <el-form-item label="审批介绍：">
          <el-input v-model="From.intro" placeholder="请输入审批名称"></el-input>
        </el-form-item>
     </el-form>
     <template #footer>
        <span class="dialog-footer" style="margin-bottom:1.25rem">
          <el-button @click="Close()">取 消</el-button>
          <el-button v-if="isAdd == 1" type="primary" @click="groupAdd(1)">确 定</el-button>
          <el-button v-if="isAdd == 2" type="primary" @click="groupAdd(2)">确 定</el-button>
          <el-button v-if="isAdd == 3" type="primary" @click="groupAdd(3)">确 定</el-button>
          <el-button v-if="isAdd == 4" type="primary" @click="groupAdd(4)">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!-- 选择部门负责人 -->
    <el-dialog :title="titleMath" v-model="dialogVisible8" width="48.5%">
      <div class="Trtfd">
        <div class="Tytt">
          <div class="hhhh">
            <div class="dsds" style="float:left;width: 87%;">
              <el-input v-model="input" placeholder="请输入内容"></el-input>
            </div>
            <div class="el_seso"><i class="el-icon-search"></i></div>
          </div>
          <div class="Blacks" >
              <div v-for="item in Parents" :key="item.id" @click="Bloo_Black(item.id)" style="cursor: pointer;">
                {{item.name}} <span v-if="Parents.length > 1">&nbsp;/&nbsp;</span>
              </div>
            </div>
          <div class="custom-tree-container">
            <div class="block" style="margin-top:16px;margin-left:15px">
            <div class="Blo_a" v-for="item in DepartmentList" :key="item.id">
              <div><span class="Blo_a2">{{item.name}}</span></div>
              <div @click="Nice_x(item.id)" style="cursor: pointer;">
                <img class="Blo_a3" src="" alt="">
                <span class="Blo_a4">下级</span>
              </div>
            </div>
            <div>
              <el-checkbox-group v-model="checkList" @change="bindCheckBox">
                <div class="Blo_b" v-for="(item,index) in StaffList" :key="index">
                  <el-checkbox :label="item.id">
                    <img :src="item.full_avatar" alt="">
                    <span>{{item.realname}}</span>
                    </el-checkbox>
                </div>                 
              </el-checkbox-group>
            </div>
            </div>
          </div>
        </div>
        <div class="Tytt" style="padding:29px 0px 20px 20px;">
          <div>已选：{{Bumen.length}}个员工</div>
          <div class="tyhh" v-for="(item,index) in Bumen" :key="index">
            <img :src="item.full_avatar" alt="">
            <span>{{item.realname}}</span>
            <i class="el-icon-close" @click="BumenClose(item)" style="float:right;padding:20px 50px;cursor: pointer;"></i>
          </div>
        </div>
      </div>
      <div class="Dityo">
        <div class="dialog-footer">
          <el-button v-if="issave == 1" type="primary" @click="staffEdit(1)" style="margin-left:15px">保存设置</el-button>
          <el-button v-if="issave == 2" type="primary" @click="staffEdit(2)" style="margin-left:15px">保存设置</el-button>
          <el-button v-if="issave == 3" type="primary" @click="staffEdit(3)" style="margin-left:15px">保存设置</el-button>
        </div>
      </div> 
    </el-dialog>

  </div>
</template>

<script>
import axios from 'axios'
export default {
  name: "basetable",
  data() {
    return {
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      activeIndex:1,
      total:'',
      titleMath:'',
      realname:'',
      FormName:'',
      isGroup:'',
      editId:'',
      formId:'',
      form_id:'',
      FormIdName:'',
      isEdit: 1,
      isAdd: 1,
      aaa: 6,
      page: {currentPage: 1,pageSize: 10}, // 分页
      search: {
        form_id: "",
        status: "",
        keyword:'',
        approval_no:'',
        staff_id: "",
        starttime: {min:'',max:''},
        endtime: {min:'',max:''},
      },
      From:{
        group_id:'',
        name:'',
        intro:'',
      },
      Form2:{
        type:'',
        starttime:'',
        endtime:'',
        days:'',
        address:'',
        price:'',
        time:'',
        goods:'',
        purpose:'',
        number:'',
        bank:'',
        account:'',
        object:'',
        hours:'',
        clients:'',
        info:'',
        invoice_title:'',
        tax_num:'',
        invoice_time:'',
        entry_time:'',
        quit_time:'',
        job:'',
        person:'',
        reason:'',
        check_list:'',
        refund_time:'',
        gotime:'',
        stamp_type:'',
        file_type:'',
        need_num:'',
        now_num:'',
        probation:'',
        understand:'',
        summary:'',
      },
      info:{
        images:'',
        content:'',
        send_one:[],
        send_two:[],
      },
      RecordInfo:{},
      statusList:[],
      formList:[],
      tableData:[],
      tableData2:[],
      FormList1:[],
      StaffList1:[],
      twoList:{},
      oneList:{},
      FormInfo:{},
      editVisible:false,
      editVisible1:false,
      editVisible2:false,
      dialogVisible10:false,
      dialogVisible8:false,
      input:'',
      checkList:[],
      DepartmentList:[],
      StaffList:[],
      Parents:[],
      Blo_Black:false,
      Bumen:[],
      newArr:[],
      Arr:[],
      value1:'',
      value2:'',
      value3:'',
    };
  }, 
  mounted(){  
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.getColumns()
    that.loadTable();
    that.getStaff()
    
  },
  methods: {
    loadTable(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/approval/recordList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
      })
    },
    loadTable2(){
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/approval/groupList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize
        },
      }).then(function (res) {
        that.tableData2 = res.data.data.list
      })
    },
    getForm(){
      var that = this
      axios.get('/admin/workbench/approval/formList',{   
      }).then(function (res) {
        that.FormList1 = res.data.data.list
      })
    },
    Bmen1(e){
      this.issave = e
      this.dialogVisible8 = true
      this.Bloo_Black(0)
      this.checkList = []
      this.Bumen = []
      this.newArr = []
    }, 
    Bloo_Black(e) {         // 通讯录选择
      var that = this
      axios.get('/admin/company/staff/subListFilter',{   
        params:{id: e}
      }).then(function (res) {
        that.DepartmentList = res.data.data.departmentList
        that.StaffList = res.data.data.staffList
        that.Parents = res.data.data.parents
      })
    },
    Nice_x(e) {         // 通讯录选择
      var that = this
      that.Blo_Black = true
      axios.get('/admin/company/staff/subListFilter',{   
        params:{id: e}
      }).then(function (res) {
        that.DepartmentList = res.data.data.departmentList
        that.StaffList = res.data.data.staffList
        that.Parents = res.data.data.parents
      })
    },
    bindCheckBox(value){     //  勾选人
      var brr = this.StaffList
      let kkk = value[value.length - 1]
      let index = brr.findIndex(obj => obj.id === kkk)
      if(value.length == 0){  //没有选中人员
        this.Bumen = []
      }else if(this.newArr.length < value.length){ //一直选中添加人员
        this.newArr.push(brr[index]) 
        this.Bumen  = this.newArr
      }else if(this.newArr.length > value.length){  //取消选中人员
        let Akk = this.newArr
        let Ahh = [...new Set(this.Arr)]
        let AMM = Ahh.filter(item => value.indexOf(item) == -1)
        let ind = this.Bumen.findIndex(obj => obj.id === AMM[0])
        Akk.splice(ind,1)
        this.Bumen = Akk
      }
      value.forEach(e => {
        this.Arr.push(e)
      });
    },
    BumenClose(data){  //删除选中人员
      let index = this.Bumen.findIndex(obj => obj.id === data.id)
      let index1 = this.checkList.findIndex(obj => obj === data.id)
      this.Bumen.splice(index,1)
      this.checkList.splice(index1,1)
    },
    staffEdit(e){  // 确定选中人员
      var that = this
      var elelm = ''
      var att = []
      var abb = []
      for (let i = 0; i < that.Bumen.length; i++) {
        att.push(that.Bumen[i].realname);
        abb.push(that.Bumen[i].id);
      }
      elelm = att.join(',')
      if(e == 1){
        that.info.send_one = abb
        that.value1 = elelm
      }else if(e == 2){
        that.info.send_two = abb
        that.value2 = elelm
      }else if(e == 3){
        that.search.staff_id = abb.toString()
        that.value3 = elelm
      }
      that.dialogVisible8 = false
      setTimeout(function(){
        that.dialogVisible8 = false
      },1)  

    },
    getStaff(){
      var that = this
      axios.get('/admin/company/staff/subListFilter',{   // 通讯录选择
          params:{id: 0}
        }).then(function (res) {
        if (res.code == 0) {
          alert('请求失败');  
        } else{ 
          that.DepartmentList = res.data.data.departmentList
          that.StaffList = res.data.data.staffList
          that.Parents = res.data.data.parents
        }
      })
    },
    HandleSee(data){
      var that = this
      that.editVisible = true
      that.titleMath = '查看审批'
      axios.get('/admin/workbench/approval/recordInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        that.oneList = res.data.data.send_one_list[0]
        that.twoList = res.data.data.send_two_list[0]
        that.RecordInfo = res.data.data.row
        that.FormIdName = res.data.data.row.form.name
        that.FormInfo = res.data.data.row.info
        that.form_id = res.data.data.row.form_id
      })
    },
    handleSelect(key){
      this.activeIndex = key
      if(key == 1){
        this.loadTable();
      }else if(key == 2){
        this.loadTable2();
      }
    },
    showAdd(e){
      if(e == 1){
        this.titleMath = '创建审批'
        this.editVisible1 = true
        this.isEdit = 1
        this.formId = ''
        this.getForm()
      }else if(e == 2){
        this.titleMath = '新建分组'
        this.editVisible2 = true
        this.isAdd = 1
        this.isGroup = 1
      }else if(e == 3){  
        this.titleMath = '创建自定义审批'
        this.editVisible2 = true
        this.isAdd = 4
        this.isGroup = 3
      }
    },
    export2Excel() {  //导出
      require.ensure([], () => {
        const { export_json_to_excel } = require('../../excel/Export2Excel');
        const tHeader = ['ID', '审批编号', '发起人' , '审批类型', '审批状态', '说明', '提交时间'];
        // 上面设置Excel的表格第一行的标题
        const filterVal = ['id', 'approval_no', 'staff.realname', 'form.name', 'status_text', 'content', 'updatetime'];
        // 上面的index、nickName、name是tableData里对象的属性
        const list = this.tableData;  //把data里的tableData存到list
        const data = this.formatJson(filterVal, list);
        export_json_to_excel(tHeader, data, '审批数据');
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    saveEdit(){  //创建审批
      var that = this
      axios({
        method: 'post',
        url: '/admin/workbench/approval/addRecord',
        data: {
          row:{
            form_id: that.formId,
            info: that.Form2,
            images: that.info.images,
            content: that.info.content,
            send_one: that.info.send_one,
            send_two: that.info.send_two,
          }
        },
      }).then(function (res) {
        console.log(res.data.data);
        if (res.data.code == 0) {
          that.$message.warning(res.data.msg);
        } else {
          that.$message.success(res.data.msg);
          that.editVisible1 = false
          that.loadTable()
        }
      })
    },
    groupAdd(e){
      var that = this
      if(e == 1){ //分组添加
        axios({
          method: 'post',
          url: '/admin/workbench/approval/addGroup',
          data: { row: {name:that.FormName}},
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.loadTable2()
          }
        })
      }else if(e == 2){ //分组编辑
        axios({
          method: 'PUT',
          url: '/admin/workbench/approval/editGroup',
          data: { 
            id:that.editId,
            row: {name:that.FormName}
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.loadTable2()
          }
        })
      }else if(e == 3){ //表单更新
        axios({
          method: 'PUT',
          url: '/admin/workbench/approval/editForm',
          data: { 
            id:that.editId,
            row: that.From
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.loadTable2()
          }
        })
      }else if(e == 4){ //表单添加
        axios({
          method: 'post',
          url: '/admin/workbench/approval/addForm',
          data: { 
            row: that.From
          },
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.editVisible2 = false
            that.getForm()
            that.loadTable2()
          }
        })
      }
    },
    AppAdd(data){
      this.formId = data.id
      this.isEdit = 2
      this.Form2 = {}
      this.value1 = ''
      this.value2 = ''
    },
    HandlEdit(e,data){
      this.editId = data.id
      if(e == 1){
        this.editVisible2 = true
        this.titleMath = '修改分组'
        this.FormName = data.name
        this.isAdd = 2
        this.isGroup = 1
      }else if(e == 2){
        this.editVisible2 = true
        this.titleMath = '编辑表单'
        this.From.name = data.name
        this.From.intro = data.intro
        this.From.group_id = data.group_id
        this.isAdd = 3
        this.isGroup = 2
      }
    },
    HandleDel(e,data){
      var that = this
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        if(e == 1){
          axios.delete('/admin/workbench/approval/delForm',{
            params:{
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable2();
            }
          })
        }else if(e == 2){
          axios.delete('/admin/workbench/approval/delGroup',{
            params:{
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable2();
            }
          })
        }else if(e == 3){
          axios.delete('/admin/workbench/approval/delRecord',{
            params:{
              id: data.id
            }
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable();
            }
          })
        }
      })
    },
    getColumns(){
      var that = this
      axios.get('/admin/workbench/approval/statisticsColumns',{   
      }).then(function (res) {
        that.statusList = res.data.data.status
        that.formList = res.data.data.form
      })
    },
    Close(){
      this.editVisible2 = false
      this.FormName = ''
    },
    handleCurrentChange(page) {
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    BtnSearch(e){
      if(e == false){
        this.search = {
          form_id: "",
          status: "",
          keyword:'',
          approval_no:'',
          staff_id: "",
          starttime: {min:'',max:''},
          endtime: {min:'',max:''},
        }
      }
      this.loadTable();
    },
    UP_Success(e) {  
      this.info.images = e.data.url
    },
    formatDate(value) {    // 时间戳
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      return y + "-" + MM + "-" + d
    },
  },
};
</script>

<style scoped>
.invoice{
  width: 100%;
}
.invoiceLi div:nth-child(2) {
  text-indent: 1em;
}
.CC{
  font-size: .875rem;
  font-family: PingFang SC;
  font-weight: bold;
  color: #262626;
  margin: 1.875rem 0 .625rem;
}
.FormIpt{
  background: #FAFAFA;
  min-height: 2.5rem;
  border-radius: .375rem;
  padding:1.25rem .9375rem;
}
.Approval{
  border: .0625rem solid #eee;
  margin-bottom: 1.25rem;
}
.ApprovalTop{
  background: #FAFAFA;
  height: 3.375rem;
  line-height: 3.375rem;
  padding: 0 1.5rem;
  display: flex;
  justify-content: space-between;
}
.ApprovalLi{
  display: flex;
  justify-content: space-between;
  height: 3.125rem;
  line-height: 3.125rem;
  padding: 0 1.5rem;
}
.ApprovalLi:hover{
  background-color: #f5f7fa;
}
.ApprovalLi div{
  display: flex;
  align-items: center;
}
.ApprovalLione div:nth-child(1){
  width: 18.75rem;
}
.ApprovalLitwo div:nth-child(1){
  height: 2rem;
  line-height: 2rem;
  background: #1890FF;
  padding: 0 1.25rem;
  border-radius: .1875rem;
  font-size: .75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  margin-right: .9375rem;
  cursor: pointer;
}
.ApprovalLitwo div:nth-child(2){
  height: 2rem;
  line-height: 2rem;
  background: #FF4D4F;
  padding: 0 1.25rem;
  border-radius: .1875rem;
  font-size: .75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  cursor: pointer;
}
.ss{
  padding: 0 0 0 1.5rem;
}
.ItemName{
  width: 9rem;
  height: 2rem;
  background: #1890FF;
  border-radius: .25rem;
  text-align: center;
  line-height: 2rem;
  color: #FFFFFF;
  cursor: pointer;
}
.ItemNames{
  font-size: 1rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8C8C8C;
  margin-top: 1.75rem;
  margin-bottom: .9375rem;
}
.lll{
  border-radius: .25rem;
  margin-bottom: .75rem;
}
.EditNewFormItemIpt{
  background: #F5F5F5;
  padding: .75rem 0;
  text-indent: 2em;
  min-height: 2.1875rem;
  cursor: pointer;
}
.EditNewFormItemIpt div:nth-child(1){
  font-size: .875rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #262626;
}
.EditNewFormItemIpt div:nth-child(2){
  font-size: .75rem;
  font-family: PingFang SC;
  font-weight: 500;
  color: #8C8C8C;
}
.topOne{
  width: 9rem;
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  text-align: center;
}
.topOne div:hover{
  color: #1890FF;
}
.rr{
  width: 100%;
}
.rr div:nth-child(1){
  width: 20% !important;
}
.rr div:nth-child(2){
  width: 80% !important;
}
.elicon{
  position: absolute;
  top: 26px;
  right: 30px;
  font-size: 16px;
  cursor: pointer;
}
</style>
